<template>
  <div id="app" class="container">
    <img class="logo" src="/dads/dadlogo.png" />
    <p class="spaced">
      <a class="filt" href="https://dad-craft.fandom.com/wiki/Dad_Craft_Wiki" target="_blank">Check out the wiki</a>
    </p>
    <p class="filters"><span>Show me </span>
      <a class="filt" :class="{actFilter:filter=='none'}" @click="filterDads('none')" >All the dads.</a>
      <a class="filt" :class="{actFilter:filter=='dadcrafting'}"  @click="filterDads('dadcrafting')">Craftin' dads.</a>
      <a class="filt" :class="{actFilter:filter=='live'}"  @click="filterDads('live')">Streamin' dads.</a>
    </p>
    <div class="columns is-multiline">
    <div v-for="dad in filteredDads" :key="dad.name" class="column dad is-one-quarter" :id="dad.name">
        <img :src="dad.pic" />
        <h2>{{dad.name}}</h2>
        <a :href="dad.twitch" target="_blank"><font-awesome-icon class="ficon" :icon="['fab', 'twitch']" /></a>
        <a :href="dad.twitter" target="_blank"><font-awesome-icon class="ficon" :icon="['fab', 'twitter']" /></a>
        <a v-if="dad.youtube" :href="dad.youtube" target="_blank"><font-awesome-icon class="ficon" :icon="['fab', 'youtube']" /></a>
        <p><a v-if="dad.live" class="live" target="_blank" :title="`Playing ${dad.game}`" :href="dad.twitch"><strong >{{dad.live && dad.game=="Minecraft" ? "DADCRAFTNG" : "LIVE"}}</strong></a></p>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      filter:"none",
      dadInfo:{
        dads:[
        ]
      }
    }
  },
  computed: {
  filteredDads() {
    switch (this.filter) {
      case 'none':
        return this.dadInfo.dads;
      case 'live':
        return this.dadInfo.dads.filter(dad => {
          return dad.live;  
        });
      case 'dadcrafting':
        return this.dadInfo.dads.filter(dad => {
          return dad.live && dad.game=='Minecraft';
        });
    
      default:
        return this.dadInfo.dads;
    }
  }
   
  },
  methods:{
    filterDads(filter){
      this.filter = filter;
    }
  },
    mounted() {
      console.log("With ❤ from WLVS");
      console.log("Questions? 🎨@wlvsatmydoor 💻@DecoyCodes");
       fetch('https://hef403otid.execute-api.us-east-1.amazonaws.com/dads/getdads', {
        method: "get",
        mode: "cors",
        redirect: "follow",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      })
        .then(response => response.json())
        .then(data => {
            this.dadInfo.dads = data
        });

  },
}
</script>

<style>
@font-face {
  font-family: "avant";
  src: url("/fonts/ITC_AVANT_GARDE_GOTHIC_BOLD.OTF") format("OpenType");
  font-weight: bolder;
}
#app {
  font-family: "avant", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 5px;
  background-color: transparent;
  color:white;
}

h1 {
  color:white;
}

.dad {
  margin-bottom:10px;
}
.logo{
  margin-top:75px;
  margin-bottom:50px;
}
.filt{
  margin-right:5px;
  color:white;
}
.filt:hover{
  border-bottom:3px solid white;
}

.filters{
  margin-bottom:40px;
}
.actFilter{
  border-bottom:3px solid white;
}

.spaced{
  margin-bottom:20px;
}

.live {
color:white;
padding:5px;
background-color:#a970ff;
}

.ficon{
  padding:3px;
  color:white;
  font-size:25px;
  margin-top:8px;
  margin-bottom:8px;
}
</style>
